<template>
  <v-container class="view_accueil">

    <!-- Carrousel -->
    <v-carousel
      v-if="hasActualites"
      height="175px"
      :show-arrows="false"
      hide-delimiter-background
      hide-delimiters
      cycle
      progress
      progress-color="pink"
    >
      <v-carousel-item
        v-for="actualite in actualites" 
        :key="actualite.id"
      >
      
      <v-img
        height="100%"
        :src="actualite.href"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        href="/actualite" 
        @click.native="$root.actualite_obj = actualite"
      >
        <v-row
          class="fill-height text-center"
          align="center"
          justify="center"
        >
          <v-card-title
            color="white"
            class="text-lg-h5"
            v-text="actualite.titre"
            style="color:white !important"
          ></v-card-title>
        </v-row>
      
      </v-img>

      </v-carousel-item>
    </v-carousel>
    
  <v-card
    class="mx-auto mt-3"
  >
    <v-img
      src="@/assets/logo-dark.svg"
      height="140px"
      style="background-color: white !important"
    ></v-img>

    <v-card-title>
      {{ $root.messages.app_name }}
    </v-card-title>

    <v-card-subtitle>
      {{ $root.messages.app_description }}
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        color="pink lighten-2"
        text
        @click="show = !show"
      >
        {{ $root.messages.read_more }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'fa-chevron-up' : 'fa-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          {{ $root.messages.remi_history }}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
  
  
    <v-alert
      text
      prominent
      color="pink"
      icon="fa-bell"
      class="mt-3"
      v-if="nb_produits > 1"
    >
      {{ nb_produits + " " + $root.messages.nb_produit_alert }}
      
      <b>{{ $root.messages.no_commande }}</b>
      
      <v-btn block class="mt-3" color="pink" outlined to="produits">
        {{ $root.messages.show_produits }}
      </v-btn>
    </v-alert>


    <!-- Que faisons nous ? -->
    <v-expansion-panels class="space-top-2x mt-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b>{{ $root.messages.what_doing }}</b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <small>{{ $root.messages.what_doing_answer }}</small>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-divider class="mx-4 mt-5 mb-2"></v-divider>
    
    <!-- Que faisons nous ? -->
    <v-expansion-panels class="space-top-2x">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b>{{ $root.messages.questions }}</b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <small>      
            {{ $root.messages.questions_answer }}
            <div class="text-center mt-5">
              <v-btn
                rounded
                color="pink"
                dark
                to="/contact"
              >
                {{ $root.messages.questions }}
              </v-btn>
            </div>
          </small>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  
    <v-divider class="mx-4 mt-5 mb-2"></v-divider>
    
    <!-- Votre avis compte ? -->
    <v-expansion-panels class="space-top-2x">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b>{{ $root.messages.avis }}</b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <small>      
            {{ $root.messages.avis_answer }}
            <div class="text-center mt-5">
              <v-btn
                rounded
                color="pink"
                to="/tinder"
                disabled
                @click.native="($root.footer_visible = false) && ($root.header_visible = false)"
              >
                {{ $root.messages.avis_bouton_text }}
              </v-btn>
            </div>
          </small>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

      
  </v-container>
</template>

<script>
import { store } from "@/store.js";

export default {
  name: 'Accueil',
  data: () => ({
    show: false,
  }),
  computed: {    
    actualites: function(){
      return store.state.actualites;
    },
    nb_produits : function(){
      return store.state.produits.length;
    },
    hasActualites: function() {
      return Object.keys(store.state.actualites).length !== 0;
    },
    hasActualitesPlusOne: function() {
      return Object.keys(store.state.actualites).length  > 1;
    },
  },
}
</script>

<style>
</style>
