<template>
  <div class="header">
    <v-toolbar
      dense
      color="pink"
      height="64"
    >
      <v-text-field
        v-model="$root.search"
        prepend-icon="fa-search"
        single-line
        outlined
        dark
        rounded
        clearable
        color="white"
        :label="$root.messages.search_produit"
        class="pt-5"
        style="margin-bottom: 0px !important; min-height: 5px"
      ></v-text-field>
      
    <div class="ml-3">
      
        <v-btn color="pink" dark small fab @click="darkMode" v-if="!$vuetify.theme.dark">
          <v-icon class="mr">fa-sun</v-icon>
        </v-btn>

        <v-btn color="pink" dark small fab @click="darkMode" v-if="$vuetify.theme.dark">
          <v-icon color="white">fa-moon</v-icon>
        </v-btn>
        
    </div>
    </v-toolbar>
    
    
    <v-list one-line v-if="$root.search != '' && searching.length == 0" style="padding: 1rem">
    
      <v-alert
        dense
        outlined
        type="error"
        icon="fa-times"
        v-if="$root.search != '' && searching.length == 0"
      >
        {{ $root.messages.error_search_produit }}
      </v-alert>
    
    </v-list>
 
    
    <v-list three-line v-if="$root.search != ''  && searching.length > 0">
      <v-list-item
        v-for="item in searching"
        :key="item.id"
        ripple
        to="/produit" 
        @click.native="setProduitInStore(item) && ($root.search = '')"
      >
        <v-img
          :src="item.href"
          class="mr-4"
          max-width="64"
          min-width="64"
        ></v-img>

        <v-list-item-content>
          <span
            class="text-uppercase font-weight-regular caption"
            v-text="item.nom"
          ></span>

          <div v-text="truncate(item.description, 50, '...')"></div>
        </v-list-item-content>
      </v-list-item>

    </v-list>
    
  </div>
</template>

<script>
import { store } from "@/store.js";

export default {
  name: "Header",
    data: () => ({
      items: store.state.produits,
    }),
    methods: {
      truncate: function (text, length, suffix) {
        if (text.length > length) {
          return text.substring(0, length) + suffix;
        } else {
          return text;
        }
      },
      darkMode() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        
        let obj_formated = {
          variable: "black",
          data: this.$vuetify.theme.dark,
          
        }
        store.commit('setObj', obj_formated);
      },
      setProduitInStore(obj){
        
        let obj_formated = {
          variable: "current_produit",
          data: obj,
          
        }
        
        store.commit('setObj', obj_formated);
        
        return true;
      },
    },
    computed: {
      searching () {
                
        if (!this.$root.search) return this.items

        const search = this.$root.search.toLowerCase()

        return store.state.produits.filter(item => {
          const text = item.nom.toLowerCase()

          return text.indexOf(search) > -1
        })
      },
    },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  
.v-input__slot{
  margin-bottom: 0px !important;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    border-color: white;
}

.v-input__icon v-input__icon--prepend{
  color: white;
}

.theme--light.v-label {
  color: white;
}

.theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: white;
}
  
</style>
