import Vue from 'vue'
import VueRouter from 'vue-router'
import Accueil from '../views/Accueil.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Accueil',
    component: Accueil
  },
  {
    path: '/produits',
    name: 'Produits',
    component: () => import(/* webpackChunkName: "about" */ '../views/Produits.vue')
  },
  {
    path: '/produit',
    name: 'Produit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Produit.vue')
  },
  {
    path: '/distributeurs',
    name: 'Distributeurs',
    component: () => import(/* webpackChunkName: "about" */ '../views/Distributeurs.vue')
  },
  {
    path: '/distributeur',
    name: 'Distributeur',
    component: () => import(/* webpackChunkName: "about" */ '../views/Distributeur.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/actualites',
    name: 'Actualites',
    component: () => import(/* webpackChunkName: "about" */ '../views/Actualites.vue')
  },
  {
    path: '/actualite',
    name: 'Actualite',
    component: () => import(/* webpackChunkName: "about" */ '../views/Actualite.vue')
  },
  {
    path: '/tinder',
    name: 'Tinder',
    component: () => import(/* webpackChunkName: "about" */ '../views/Tinder.vue')
  },
  {
    path: '/distributeur_produit',
    name: 'Distributeur_produit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Distributeur_produit.vue')
  },

]

const router = new VueRouter({  
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0, behavior: 'smooth',}
  },
  routes
})

export default router
