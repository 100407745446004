<template>
    
    <v-bottom-navigation
      :value="value"
      color="pink"
      grow
      app
    >

      <v-btn x-large to="/">
        <span>Accueil</span>
        <v-icon>fa-home</v-icon>
      </v-btn>
      
      <v-btn x-large to="/distributeurs">
        <span>Distributeurs</span>
        <v-icon>fa-boxes</v-icon>
      </v-btn>
      
      <v-btn x-large to="/produits">
        <span>Produits</span>
        <v-icon>fa-utensils</v-icon>
      </v-btn>

      <v-btn x-large to="/actualites">
        <span>Actus</span>
        <v-icon>fa-newspaper</v-icon>
      </v-btn>
<!--
      <v-btn x-large to="/tinder">
        <span>Tinder</span>
        <v-icon>fa-heart</v-icon>
      </v-btn>
-->    
<!--
      <v-btn>
        <span>Contact</span>
        <v-icon>fa-address-book</v-icon>
      </v-btn>
-->
      
    </v-bottom-navigation>
</template>

<script>
export default {
  name: "footer_component",
  data: () => ({ value: 0 }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
