<template>
  <v-app>
  
    <!-- Header component -->
    <Header v-if="$root.header_visible" />
  
    <v-main>
    <v-offline
      online-class="online"
      offline-class="offline"
      @detected-condition="amIOnline">
      <template v-slot:[onlineSlot] :slot-name="onlineSlot">
        
        <!-- Router view -->    
        <router-view v-if="$root.search == ''" class="view"></router-view>

      </template>
    </v-offline>
    
    <!-- Overlay -->
    <v-overlay :value="$root.overlay" style="z-index: 999 !important">
      
      <v-img
        alt="Vuetify Logo"
        contain
        center
        src="@/assets/logo.svg"
      />
      <div class="text-center">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </div>
    </v-overlay>    
    
      <v-dialog
        v-model="dialog"
        max-width="300"
      >
        <v-card>
          <h2 style="padding: 15px">{{ $root.messages.filtres }}</h2>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;">
            <v-radio-group
              v-model="$root.filtres"              
              column
            >
              <v-radio
                label="Aucun filtre"
                value=""
                on-icon="fa fa-square"
                off-icon="far fa-square"
                @change="$root.overlay = !$root.overlay && resetFilters()"
              ></v-radio>
              <v-radio
                v-for="distributeur in store.state.distributeurs"
                :key="distributeur.id"
                :label="distributeur.nom"
                :value="distributeur"
                on-icon="fa fa-square"
                off-icon="far fa-square"
                color="dark"
                @change="$root.overlay = !$root.overlay"
              ></v-radio>
            </v-radio-group>
  
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions elign="right">
            <v-spacer></v-spacer>
            <v-btn
              color="pink darken-1"
              text
              @click="dialog = false"
            >
              {{$root.messages.close}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    
    <div v-if="this.$route.name == 'Produits'" style="width: 100% !important; height: 64px; position: fixed; bottom: 44px">

      <v-row
        align="center"
        justify="space-around"
      >
        <v-btn
          large
          rounded
          class="v-btn--example"
          @click.stop="dialog = true"
        >
          <v-icon>fas fa-filter</v-icon>
          {{ text_filters }}
        </v-btn>
      </v-row>
    </div>
    
    </v-main>
    
    
    <v-footer app style="padding: 0px !important;" v-if="$root.footer_visible"> 
      <!-- Footer component -->
      <Footer />
    </v-footer>
    
  </v-app>
</template>

<script>

import VOffline from 'v-offline';
import axios from 'axios';

import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import { store } from "@/store.js";

const headers = { 
  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  };

export default {
  name: 'app',
  components: {
    VOffline,
    Header,
    Footer,
  },
  data () {
    return {
      onlineSlot: 'online',
      offlineSlot: 'offline',
      distributeurs: null,
      overlay: false,
      store: store,
      dialog: false,
      messages : store.state.messages,
    }
  },
  computed: {    
    text_filters: function() {
            
      let default_text = "filtre";
      
      if(this.$root.filtres != 0 ){
        default_text = "filtres (1)";
      }
      
      return default_text;
      
    }
  },
  mounted: function() {
    
    this.refreshdatas();
    
    // Toggle black mode.
    this.$vuetify.theme.dark = store.state.black;
    
  },
  methods: {
    amIOnline(e) {
      this.$root.onLine = e;
    },
    resetFilters(){
      
      this.$root.filtres = [];
      this.$root.overlay = false;
      
    },
    getProduits: function (distributeur_id) {        
      axios
        .get(this.$root.base_url + "getProduitInDistributeur/"+distributeur_id)
        .then(res => {
          let obj = {
            variable: "produit_distributeur",
            data: res.data,
            sous_id: distributeur_id,
          }
          //store.state.distributeurs = res.data;
          store.commit('setObj', obj);
        })          
    },
    refreshdatas(){
    // Récupération des produits.
    axios
      .get(this.$root.base_url + "call/produit?online=1")
      .then(res => {
        let obj = {
          variable: "produits",
          data: res.data,
        }
        //store.state.distributeurs = res.data;
        store.commit('setObj', obj);
      })
      
    // Récupération des catégories.
    axios
      .get(this.$root.base_url + "call/categorie?online=1")
      .then(res => {
        let obj = {
          variable: "categories",
          data: res.data,
        }
        //store.state.distributeurs = res.data;
        store.commit('setObj', obj);
      })
      
    // Récupération des catégories.
    axios
      .get(this.$root.base_url + "getMessages", headers)
      .then(res => {
        let obj = {
          variable: "messages",
          data: res.data,
        }
        //store.state.distributeurs = res.data;
        store.commit('setObj', obj);
      })
      
    // Récupération des étapes.
    axios
      .get(this.$root.base_url + "call/etape?online=1&order_by=position.ASC")
      .then(res => {
        let obj = {
          variable: "etapes",
          data: res.data,
        }
        //store.state.distributeurs = res.data;
        store.commit('setObj', obj);
      })
      
    // Récupération des actualites.
    axios
      .get(this.$root.base_url + "call/article?online=1")
      .then(res => {
        let obj = {
          variable: "actualites",
          data: res.data,
        }
        //store.state.distributeurs = res.data;
        store.commit('setObj', obj);
      })
      
    // Récupération des contacts.
    axios
      .get(this.$root.base_url + "call/contact")
      .then(res => {
        let obj = {
          variable: "contacts",
          data: res.data,
        }
        //store.state.distributeurs = res.data;
        store.commit('setObj', obj);
      })
      
      
    // Récupération des distributeurs.
    axios
      .get(this.$root.base_url + "call/distributeur?online=1")
      .then(res => {
        let obj = {
          variable: "distributeurs",
          data: res.data,
        }
        //store.state.distributeurs = res.data;
        store.commit('setObj', obj);
        
        
        // Récupération des produits dans ce distributeur.
        for (var i = 0; i < store.state.distributeurs.length; i++) {        
          this.getProduits(store.state.distributeurs.[i].id);
        }
        
      })
      
      
      //setTimeout(this.refreshdatas, 3000);
      
    },
    
    
  },
  created: function() {
    
    


    
  },
}
</script>

<style>
  
.theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea, .theme--light.v-label, .v-input__icon v-input__icon--prepend {
    color: #000 !important;
}

#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.offline2 {
  background-color: #fc9842;
  background-image: linear-gradient(315deg, #FFFFFF 0%, #FFFFFF 74%);
  height: 100%;
}
.online2 {
  background-color: #00b712;
  background-image: linear-gradient(315deg, #FFFFFF 0%, #FFFFFF 74%);
  height: 100%;

}

</style>