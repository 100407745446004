import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import { store } from "./store.js";

//import axios from "axios";

Vue.config.productionTip = false

const vue_instance = new Vue({
  vuetify,
  router,
  data: {
    produit_obj: {},
    distributeur_obj: {},
    actualite_obj: {},
    messages : store.state.messages,
    store: store,
    footer_visible: true,
    header_visible: true,
    filtres: [],
    search: '',
    onLine: null,
    overlay: false,
    base_url: "https://manager.aucoeurdelamalice.com/api/manager/",
  },
  filters: {
    strippedContent: function(string) {
      return string.replace(/<\/?[^>]+>/ig, " "); 
    }
  },
  methods : {
   isEmpty: function(obj) {
  
      // null and undefined are "empty"
      if (obj == null) return true;
  
      // Assume if it has a length property with a non-zero value
      // that that property is correct.
      if (obj.length > 0)    return false;
      if (obj.length === 0)  return true;
  
      // If it isn't an object at this point
      // it is empty, but it can't be anything *but* empty
      // Is it empty?  Depends on your application.
      if (typeof obj !== "object") return true;
  
      // Otherwise, does it have any properties of its own?
      // Note that this doesn't handle
      // toString and valueOf enumeration bugs in IE < 9
      for (var key in obj) {
          if (hasOwnProperty.call(obj, key)) return false;
      }
  
      return true;
  },

  },
  watch: {
    overlay() { 
      setTimeout(() => {
        this.$root.overlay = false
      }, 1000)
    },
  },
  render: h => h(App)
}).$mount('#app')


// Wait for the deviceready event to start the render
document.addEventListener("deviceready", () => {
  // eslint-disable-next-line
  console.log("Ready, Render the App");
  if(window.FirebasePlugin){
    
window.FirebasePlugin.grantPermission(function(hasPermission){
  
    console.log("Permission was " + (hasPermission ? "granted" : "denied"));

  
    window.FirebasePlugin.getToken(function(token) {
    // save this server-side and use it to push notifications to this device
    store.deviceFCMToken = token;
    }, function(error) {
      console.error(error);
    });

    // Get notified when a token is refreshed
    window.FirebasePlugin.onTokenRefresh(function(token) {
        // save this server-side and use it to push notifications to this device
        console.log("Refresh to get new token: " + token);
        store.deviceFCMToken = token;
    }, function(error) {
        alert(error);
    });

    // Get notified when the user opens a notification
    window.FirebasePlugin.onMessageReceived(function(message) {
      console.log("Message type: " + message.messageType);
      if(message.messageType === "notification"){
          //alert("Notification message received");
          console.log("Notification", message);
        if(message.tap){
          //alert("Tapped in " + message.tap);
          console.log("Je tape sur la notification");
          
          // Recherche si c'est une actualité / distributeur / produit.
          
          let model = message.model;
          let object_id = message.object_id;
          let objet = null;
          
          
          if(model == "actualite"){
            
            console.log(store);
            
            for(let index in store.state.actualites){
              
              let actualite = store.state.actualites[index];
              
              if(actualite.id == object_id){
                objet = actualite;
                
              }
              
            }
            
            
            //Si on le trouve pas.
            if(objet == null){
              console.log("Je n'ai pas trouver l'objet.");
            }else{
              console.log("J'ai trouver l'objet.");
              
              vue_instance.data.actualite_obj = objet;
              this.$router.push("Actualite");

            }
                        
          }
          
          // On recherche l'id fournit de l'objet dans les ids que l'on possède.
          
          // Si je ne le possède pas, je re-télécharge les datas venant du serveur.
          
          // Si je le possède, je l'affecte en tant que objet principale.
          // J'active l'overlay.
          // je redirige vers la page.
          // Je cache l'overlay.
        }
      }
    }, function(error) {
      console.error(error);
    });
    
    window.FirebasePlugin.subscribe("general", function(){
        console.log("Subscribed to topic");
    }, function(error){
         console.error("Error subscribing to topic: " + error);
    });
});

    
  }
      
  // Lanch init function ??    
  //init();
  
});

// If we are not in Cordova, manually trigger the deviceready event
const isCordovaApp = (typeof window.cordova !== "undefined");
if (!isCordovaApp){
  document.dispatchEvent(new CustomEvent("deviceready", {}));
}
