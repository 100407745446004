// global store file for maintainig the states to share data between the components

import Vuex from "vuex";
import Vue from "vue";

let messages = 
{
 "app_name": "Au Coeur de la Malice",
 "app_description" : "Votre traiteur de la région Haut de France",
 "read_more": "Lire plus",
 "no_commande" : "Aucune commande en ligne n'est disponible ! ",
 "remi_history": "A l’age de 12 ans, je suis tombé dans l’amour de la restauration grâce à mon oncle Robert. J’ai intégré à 14 ans l’école hôtelière de Bailleul. Ayant exercé auprès de grands chefs talentueux, j’ai pu acquérir de bonnes bases ! De la cuisine en collectivité au restaurant traditionnel, où j’occupais un poste de chef, j’ai trouvé ma voie en me lançant en tant que Traiteur à domicile. Je serais ravi de vous réaliser une prestation de qualité, élaborée avec des produits frais et locaux.",
 "nb_produit_alert":" produits(s) sont actuellement disponibles dans nos distributeurs ! Découvrez en cliquant sur le boutons les produits dans le distributeur le plus proche de chez vous !",
 "show_produits" : "Voir les produits",
 "what_doing" : "Que faisons-nous ?",
 "what_doing_answer" : "Pour vos Mariages, baptêmes, anniversaires mais aussi séminaires, réunions et autres occasions personnelles et professionnelles. Rémi vous accompagne dans l’organisation de vos manifestations. Nos offres s’adaptent et vous fournissent une prestation de qualité qui ravira vos convives en toutes circonstances. De 10 à 300 personnes Cuisine sur place et devant vous Déplacement dans les Hauts-de-France, facturable ou gratuit suivant le nombre de convives. Les tarifs sont dégressifs en fonction du nombre de convives et restent toujours abordables",
 "questions": "Une question ?",
 "questions_answer": "Vous pouvez nous retrouvez sur nos différents réseaux sociaux ainsi que notre site web.",
 "avis": "Votre avis compte !",
 "avis_answer" : "Donner nous votre avis sur les plats que vous aimez et que vous aimez pas afin d'améliorer la carte. Nous vous proposons de donner votre avis d'une manière originale !Cliquez sur le bouton pour activer le mode 'Tinder de la malice !'",
"avis_bouton_text" : "Tinder de la malice (A venir)",
"nos_distributeur_titre": "Nos distributeurs",
"nos_distributeur_text": "Il s'agit de la liste complète des distributeurs en activités. Vous pouvez y acheter nos produits directement en vous y rendant.",
"cest_quoi_titre" : "C'est quoi ?",
"cest_quoi_text" : "Nous avons le plaisir de vous accueillir dans nos distributeurs en libre service ouvert dans la région du Nord.Des plats, des desserts, des plateaux disponibles touts les jours et préparer par notre équipe pour vos papilles. A votre écoute pour vous proposez vos plats préférés et à l'écoute pour les retours clients !",
"comment_ça_marche": "Comment ça marche ?",
"nos_produits_titre" : "Nos produits",
"nos_produits_text" : "Il s'agit de la liste complète des produits que nous proposons actuellement dans notre gamme. Hésitez pas à voter pour les produits pour les retrouvez plus régulièrement dans nos distributeurs.",
"nos_actualites" : "Nos actualitées",
"nos_actualites_text" : "Il s'agit des informations que souhaitons communiquer avec vous ! Des dates importantes ou bien des évenements !",
"search_produit": "Rechercher un produit ...",
"read": "Lire",
"error_search_produit" : "Le produit n'est pas disponible ou n'existe pas.",
"comment_my_rendre": "Comment m'y rendre ?",
"show_produits_distributeurs" : "Voir les produits du distributeur",
"horaire": "Horaires d'ouverture",
"adresse": "Adresse",
"disponibilite_titre": "Disponibilité",
"disponibilite" : "Ce produit est disponible dans le/les distributeur(s) suivants",
"not_description": "Malheureusement aucune description n'est disponible pour ce produit !",
"not_disponible" : "Ce produit n'est disponible dans aucun distributeur actuellement.",
"not_vote_internet": "Vous ne pouvez pas voter sans connexion internet.",
"note_titre": "Note",
"note": "Notez ce produit",
"my_note": "Votre note",
"note_text": "Si vous avez aimer ce produit vous pouvez lui attribuer une note, cela va permettre de savoir vos retour a des fins d'améliorations.",
"send_note": "J'envoie ma note",
"go_map": "J'y vais !",
"rejoindre": "Rejoindre",
"select_distributeur": "Choisir le distributeur",
"filtres": "Filtrer les produits par distributeur",
"close": "Fermer",

}



Vue.use(Vuex);

// Default store state.
let store_state   =  {};

const store_state_default = {
  deviceFCMToken:"",
  distributeurs: {},
  produits: {},
  produit_distributeur: {},
  actualites: {},
  categories: {},
  rated_ids: {},
  etapes: {},
  black: false,
  contacts: {},
  messages: messages,
  current_produit: {},
  liked_produits_ids: [], // Array des ids des produits liked.
  unliked_produits_ids: [], // Array des ids des produits unliked.
}

// If store state find in localStorage.
if(localStorage.getItem('store')){
  // Retrieve store state from localStorage.
  store_state = JSON.parse(localStorage.getItem('store'));
      
  //Vérification que le localStorage est à jour.
  
  //Add property if necessary
  for ( let prop in store_state_default ) {
        
    //Si il n'a pas la propriété, on lui ajoute celle par défaut.
    if(!Object.prototype.hasOwnProperty.call(store_state, prop)){

      //Affectation.
      store_state[prop] = store_state_default[prop];
    }
  }
  
  //Remove property if necessary
  for ( let prop in store_state ) {
        
    //Si il n'a pas la propriété, on lui ajoute celle par défaut.
    if(!Object.prototype.hasOwnProperty.call(store_state_default, prop)){

      //Remove.
      delete store_state[prop];
    }
  }

}else{
  store_state = store_state_default;
}

export const store = new Vuex.Store({
  state: store_state,
  getters: {
    getLikedProducts: (state) => {
      return state.liked_produits_ids;
    },
    getUnLikedProducts: (state) => {
      return state.unliked_produits_ids;
    },
    getNoteCurrentProduit: (state) => {
      return state.rated_ids[state.current_produit.id];
    },
    getCurentProduit: (state) => {
      return state.current_produit;
    }
  },
  mutations: {
    setObj (state, payload) {
      // mutate state
      
      if(payload.sous_id != undefined){
        store.state[payload.variable][payload.sous_id] = payload.data;
      }else{
        store.state[payload.variable] = payload.data;
      }
    } 
  },
  methods: {},
});

let time = 3000; // en ms.

saveStore();

function saveStore(){
  localStorage.store = JSON.stringify(store.state);
  setTimeout(saveStore, time);
}



