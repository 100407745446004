import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: false,
  icons: {
    iconfont: 'fa4',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#e91e63',
        accent: '#e91e63',
        secondary: '#b0bec5',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      },
      dark: {
        primary: '#e91e63',
        accent: '#FF4081',
        secondary: '#b0bec5',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      },
    }
  }
})